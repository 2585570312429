import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonActionSheet,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthState, AuthStore, MyUser } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import styles from "./store.module.scss";
import {
  apps,
  ellipsisHorizontal,
  ellipsisVertical,
  images,
  map,
  personAdd,
  settings,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { DataGame, GameState } from "../../_redux/models/game.model";
import Enumerable from "linq";
import { gameService } from "../../_redux/services";
import { Tienda } from "../../_redux/models/tienda.model";
import { Producto } from "../../_redux/models/producto.model";
import {
  filtrarMeuDatos,
  OrdenarLoja,
  YaPaso1HoraDesdeQueGano5VidasGratis,
} from "../../utils/functions";
import { authActions, gameActions } from "../../_redux/actions";
import {
  AdLoadInfo,
  AdMob,
  AdMobRewardItem,
  InterstitialAdPluginEvents,
  RewardAdOptions,
  RewardAdPluginEvents,
} from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import { MenuApp } from "../../utils/functions";
import { appActions } from "../../_redux/actions/app.actions";
import { AppStore } from "../../_redux/models/app.model";
import AdsLateralPropio from "../../components/Ads/AdsLateralPropio";
import BannerLocalLateralGrande from "../../components/Ads/BannerLocalLateralGrande";
import CrearCuenta from "../../components/Modal/CrearCuenta";
import GanaLogro from "../../components/Logro/GanaLogro";
import Media from "react-media";
import { LogroState } from "../../_redux/models/logro.models";

const Store: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const logros: LogroState = useSelector((state: any) => state.logro);
  const [showModal, setShowModal] = useState(false);
  const [urlWiki, setUrlWiki] = useState("");
  const [present, dismiss] = useIonActionSheet();
  const [searchText, setSearchText] = useState("");
  const [lista, setLista] = useState<Tienda[]>([]);
  const [eventoPublicidad, setEventoPublicidad] = useState(false);
  let tempCargarPublicidadMoneda = true;
  const appConfig = useSelector((store: AppStore) => store.app);
  const [loginModal, setLoginModal] = useState(false);
  const [logroGanaModal, setLogroGanaModal] = useState(false);
  const [logroId, setLogroId] = useState(-1);
  const abrirModal = async (data: DataGame, triadvisor: boolean) => {
    setUrlWiki(triadvisor === true ? data.V : data.W);
    setShowModal(true);
  };
  let primeraCompra = false;
  let PublicidadReward = false;
  let monedaPublicidadReward = false;

  const abrirModalLogin = () => {
    setLoginModal(true);
  };

  const cerrarModalLogin = () => {
    setLoginModal(false);
  };

  const cerrarModaGanalLogro = () => {
    setLogroGanaModal(false);
    setLogroId(-1);
  };

  const comprar = async (item: Tienda) => {
    try {
      dispatch(gameActions.IsBusy());
      authUser.user.aspj = item.id.toString();
      if (item.id === Producto.Cafe) {
        window.open("https://www.buymeacoffee.com/pekegames", "_blank");
      } else if (item.id === Producto.Moneda_Publicidad) {
        //MOSTRAR ANUNCIO, SI EL USUARIO TERMINA, LE DAMOS LAS 5 MONEDAS.
        //await actualizarPublicidad(authUser, dispatch, cargarTienda, cargado);
        await CargarPublicidadBonusVideo(true);
      } else if (item.id === Producto.QUITAR_PUBLICIDAD) {
        await CargarPublicidadBonusVideo(false);
      } else {
        switch (item.id) {
          case Producto.AYUDA_50:
            authUser.user.DatosExtendido.Ayuda50++;
            break;
          case Producto.Ayuda_Contestar_2Veces:
            authUser.user.DatosExtendido.AyudaContestar2Veces++;
            break;
          case Producto.MAS_TIEMPO:
            authUser.user.DatosExtendido.AyudaMasTiempo++;
            break;
          case Producto.Ayuda_Pregunta_Correcta:
            authUser.user.DatosExtendido.AyudaPreguntaCorrecta++;
            break;
          case Producto.Recuperar_Puntos_Perdido:
            authUser.user.DatosExtendido.RecuperarPuntosPerdido++;
            break;
          case Producto.VIDAS_5_GRATIS:
            authUser.user.DatosExtendido.Vida =
              authUser.user.DatosExtendido.Vida + 3;
            const currentDate = new Date();
            localStorage.setItem("lastSavedDate", currentDate.toISOString());
            break;
          default:
            break;
        }
        cargarTienda(authUser.user);
        authUser.user.aspj = OrdenarLoja(authUser.user);
        const comprarReponse = await gameService.ComprarAyuda(authUser.user);
        if (comprarReponse !== null) {
          dispatch(authActions.authSucess(comprarReponse));
          dispatch(
            appActions.showToast({
              message: t("successful_purchase"),
              type: "success",
              position: "top",
              icon: "globe",
            })
          );
          cargarTienda(comprarReponse);
        }
      }

      dispatch(gameActions.QuitarIsBusy());

      if (
        logros !== null &&
        logros.logro !== null 
      ) {
        if (!logros?.logro?.find((x) => x.LogroId === 27)?.TieneLogro) {
          primeraCompra = true;
          if (primeraCompra) {
            dispatch(appActions.showLogro(27));
            primeraCompra = false;
          }
        } else {
          primeraCompra = false;
        }
      }
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
      dispatch(authActions.errorSistema(error.message));
    }

    async function CargarPublicidadBonusVideo(cargarPublicidadMoneda: boolean) {
      try {
        let eventoRewarded = null;
        if (!cargarPublicidadMoneda) {
          dispatch(appActions.setAppHabilitarINTERTINALBanner());
        }

        AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
          // Subscribe prepared rewardVideo
        });

        AdMob.addListener(RewardAdPluginEvents.FailedToLoad, () => {
          bannerLocalPagarPremio();
        });

        AdMob.addListener(RewardAdPluginEvents.Dismissed, async () => {
          dispatch(gameActions.QuitarIsBusy());
          if (PublicidadReward === true) {
            PublicidadReward = false;
            dispatch(appActions.setAppOcultarBanner());
            const result = await AdMob.hideBanner().catch((e) =>
              console.log(e)
            );
            if (result === undefined) {
              return;
            }

            const app: HTMLElement =
              document.querySelector("ion-router-outlet");
            app.style.marginTop = "0px";
            app.style.marginBottom = "0px";
            dispatch(authActions.errorSistema(t("advertising_disabled")));
            dispatch(appActions.setAppOcultarINTERTINALBanner());
          }
          if (monedaPublicidadReward) {
            monedaPublicidadReward = false;
            authActions.errorSistema(t("very_good_won_5_coins"));
          }
          eventoRewarded.remove();
        });

        AdMob.addListener(RewardAdPluginEvents.FailedToLoad, async () => {
          dispatch(gameActions.QuitarIsBusy());
          dispatch(authActions.errorSistema(t("advertising_not_available")));
        });

        eventoRewarded = AdMob.addListener(
          RewardAdPluginEvents.Rewarded,
          async (rewardItem: AdMobRewardItem) => {
            // Subscribe user rewarded

            if (!cargarPublicidadMoneda) {
              PublicidadReward = true;
            } else {
              monedaPublicidadReward = true;
              authUser.user.DatosExtendido.MonedaGlobal += 5;
              if (authUser.user.Id === "-1") {
                localStorage.setItem(
                  "ctpk",
                  filtrarMeuDatos(JSON.stringify(authUser.user))
                );
                dispatch(authActions.authSucess(authUser.user));
                cargarTienda(authUser.user);
              } else {
                authUser.user.aspj = OrdenarLoja(authUser.user);
                const comprarReponse = await gameService.ComprarAyuda(
                  authUser.user
                );
                if (comprarReponse !== null) {
                  dispatch(authActions.authSucess(comprarReponse));
                  cargarTienda(comprarReponse);
                }
              }
            }

            dispatch(gameActions.QuitarIsBusy());

            console.log(rewardItem);
          }
        );

        if (cargarPublicidadMoneda) {
          //Cargar publicidad moneda

          if (Capacitor.isNativePlatform()) {
            const options: RewardAdOptions = {
              adId: isPlatform("android")
                ? "ca-app-pub-8932590852481744/2710154431"
                : "ca-app-pub-8932590852481744/2129384719",
              // npa: true
              // ssv: {
              //   userId: "A user ID to send to your SSV"
              //   customData: JSON.stringify({ ...MyCustomData })
              //}
            };
            await AdMob.prepareRewardVideoAd(options);
            await AdMob.showRewardVideoAd();
            dispatch(gameActions.QuitarIsBusy());
          } else {
            bannerLocalPagarPremio();
          }
        } else {
          //Cargar publicidad eliminar publicidad
          const options: RewardAdOptions = {
            adId: isPlatform("android") ? "" : "",
            // npa: true
            // ssv: {
            //   userId: "A user ID to send to your SSV"
            //   customData: JSON.stringify({ ...MyCustomData })
            //}
          };
          await AdMob.prepareRewardVideoAd(options);
          await AdMob.showRewardVideoAd();
          dispatch(gameActions.QuitarIsBusy());
        }
        dispatch(gameActions.QuitarIsBusy());
      } catch (error) {
        dispatch(gameActions.QuitarIsBusy());
      }
    }
  };
  const cargarTienda = (perfil: MyUser) => {
    let mis_moneda = perfil?.DatosExtendido?.MonedaGlobal;
    let auxList: Tienda[] = [];

    // if (Capacitor.isNativePlatform()) {
    auxList.push({
      id: Producto.Moneda_Publicidad,
      producto: t("store_publicidad_title"),
      descripcion: t("store_publicidad_descripcion"),
      cantidad: 5,
      precio: 0,
      icono: "/assets/icon/moneda.png",
      puedoComprar: true,
    });
    // }

    auxList.push({
      id: Producto.VIDAS_5_GRATIS,
      producto: t("life_free"),
      descripcion: t("store_life_free_descripcion"),
      cantidad: 5,
      precio: 0,
      icono: "/assets/icon/vida.png",
      puedoComprar:
        YaPaso1HoraDesdeQueGano5VidasGratis() && authUser.isAuth ? true : false,
    });

    auxList.push({
      id: Producto.COMPRAR_VIDA,
      producto: t("life"),
      descripcion: t("store_life_descripcion"),
      cantidad: 1,
      precio: 1,
      icono: "/assets/icon/vida.png",
      puedoComprar: mis_moneda >= 1 ? true : false,
    });

    auxList.push({
      id: Producto.Recuperar_Puntos_Perdido,
      producto: t("store_mas_recuperar_puntos_title"),
      descripcion: t("store_mas_recuperar_puntos_descrition"),
      cantidad: 2,
      precio: 1,
      icono: "/assets/icon/ayudaRecuperarPuntos.png",
      puedoComprar: mis_moneda >= 1 ? true : false,
    });

    auxList.push({
      id: Producto.Ayuda_Contestar_2Veces,
      producto: t("store_2_oportunidad_title"),
      descripcion: t("store_2_oportunidad_description"),
      cantidad: 1,
      precio: 2,
      icono: "/assets/icon/oportunidadDoble.png",
      puedoComprar: mis_moneda >= 2 ? true : false,
    });

    auxList.push({
      id: Producto.AYUDA_50,
      producto: t("store_50_title"),
      descripcion: t("store_50_description"),
      cantidad: 1,
      precio: 3,
      icono: "/assets/icon/ayuda50.png",
      puedoComprar: mis_moneda >= 3 ? true : false,
    });

    auxList.push({
      id: Producto.MAS_TIEMPO,
      producto: t("store_mas_tiempo_title"),
      descripcion: t("store_mas_tiempo_description"),
      cantidad: 1,
      precio: 5,
      icono: "/assets/icon/ayudaMasTiempo.png",
      puedoComprar: mis_moneda >= 5 ? true : false,
    });

    auxList.push({
      id: Producto.Ayuda_Pregunta_Correcta,
      producto: t("store_respuesta_correcta_title"),
      descripcion: t("store_respuesta_correcta_description"),
      cantidad: 1,
      precio: 100,
      icono: "/assets/icon/ayudaRespuestaCorrecta.png",
      puedoComprar: mis_moneda >= 100 ? true : false,
    });

    if (!isPlatform("ios")) {
      auxList.push({
        id: Producto.Cafe,
        producto: t("store_coffe_title"),
        descripcion: t("store_coffe_description"),
        cantidad: 1,
        precio: 0,
        icono: "/assets/icon/cafe.png",
        puedoComprar: true,
      });
    }
    // if (Capacitor.isNativePlatform()) {
    //   auxList.push({
    //     id: Producto.QUITAR_PUBLICIDAD,
    //     producto: t("store_remove_ads_title"),
    //     descripcion: t("store_remove_ads_description"),
    //     cantidad: 1,
    //     precio: 0,
    //     icono: "/assets/icon/ads.png",
    //     puedoComprar: true,
    //   });
    // }
    setLista(auxList);
  };

  useEffect(() => {
    cargarTienda(authUser.user);
    if (authUser?.user?.NuevoUsuarioEnElJuego) {
      setLogroId(1);
      setLogroGanaModal(true);
    }
  }, [authUser]);

  const initService = async () => {
    try {
      if (authUser?.user.Auth && authUser?.user?.Id !== "-1") {
        const perfil = await gameService.Perfil();
        console.log("perfil", perfil);
        dispatch(authActions.cargarDatosSinNavegacion(perfil));
        //setMyProfile(perfil);
        // cargarTienda(perfil);
      } else {
        alert("modo invitado");
      }
    } catch (error) {}
  };

  const bannerLocalPagarPremio = async () => {
    dispatch(appActions.bannerLocalModalMostrar());
    monedaPublicidadReward = true;
    authUser.user.DatosExtendido.MonedaGlobal += 5;
    if (authUser.user.Id === "-1") {
      localStorage.setItem(
        "ctpk",
        filtrarMeuDatos(JSON.stringify(authUser.user))
      );
      dispatch(authActions.authSucess(authUser.user));
      cargarTienda(authUser.user);
    } else {
      authUser.user.aspj = OrdenarLoja(authUser.user);
      const comprarReponse = await gameService.ComprarAyuda(authUser.user);
      if (comprarReponse !== null) {
        dispatch(authActions.authSucess(comprarReponse));
        cargarTienda(comprarReponse);
      }
    }
  };

  const gameGlobal: GameState = useSelector((state: any) => state.game);
  return (
    <IonPage id="page-photo">
      <IonHeader>
        <IonToolbar className="menu_movil_2">
          {MenuApp(present, t)}
          <IonTitle> {t("store")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bg_app_content_photo">
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral className="hide_pc"></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="7" className="centro_pagina_photo ">
            <IonContent>
              {authUser.isAuth && (
                <div className="contenido_scroll">
                  <IonCard className={styles.card_ayuda}>
                    <IonCardTitle className={styles.card_ayuda_titulo}>
                      {t("my_little_help")}
                    </IonCardTitle>
                    <IonCardSubtitle className={styles.card_ayuda_texto}>
                      {t("store_texto_parte_1", {
                        nick: authUser.user?.Nick,
                        moneda: authUser.user?.DatosExtendido?.MonedaGlobal,
                      })}
                      <img
                        width={30}
                        src="/assets/icon/moneda.png"
                        alt={t("coin")}
                      ></img>{" "}
                      {t("store_texto_parte_2")}
                    </IonCardSubtitle>
                    <IonCardContent>
                      <IonRow>
                        <IonCol className="text_center">
                          <img
                            alt={t("life_50")}
                            src="/assets/icon/vida.png"
                            className="icon_ayuda_perfil"
                          ></img>
                        </IonCol>
                        <IonCol className="text_center">
                          <img
                            alt={t("help_50")}
                            src="/assets/icon/ayuda50.png"
                            className="icon_ayuda_perfil"
                          ></img>
                        </IonCol>
                        <IonCol className="text_center">
                          <img
                            alt={t("help_longer")}
                            src="/assets/icon/ayudaMasTiempo.png"
                            className="icon_ayuda_perfil"
                          ></img>
                        </IonCol>
                        <IonCol className="text_center">
                          <img
                            alt={t("help_not_to_lose_points")}
                            src="/assets/icon/ayudaRecuperarPuntos.png"
                            className="icon_ayuda_perfil"
                          ></img>
                        </IonCol>
                        <IonCol className="text_center">
                          <img
                            alt={t("help_correct_answer")}
                            src="/assets/icon/ayudaRespuestaCorrecta.png"
                            className="icon_ayuda_perfil"
                          ></img>
                        </IonCol>
                        <IonCol className="text_center">
                          <img
                            alt={t("help_doble")}
                            src="/assets/icon/oportunidadDoble.png"
                            className="icon_ayuda_perfil"
                          ></img>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="text_center texto_store_numero">
                          {authUser.user?.DatosExtendido?.Vida}
                        </IonCol>
                        <IonCol className="text_center texto_store_numero">
                          {authUser.user?.DatosExtendido?.Ayuda50}
                        </IonCol>
                        <IonCol className="text_center texto_store_numero">
                          {authUser.user?.DatosExtendido?.AyudaMasTiempo}
                        </IonCol>
                        <IonCol className="text_center texto_store_numero">
                          {
                            authUser.user?.DatosExtendido
                              ?.RecuperarPuntosPerdido
                          }
                        </IonCol>
                        <IonCol className="text_center texto_store_numero">
                          {authUser.user?.DatosExtendido?.AyudaPreguntaCorrecta}
                        </IonCol>
                        <IonCol className="text_center texto_store_numero">
                          {authUser.user?.DatosExtendido?.AyudaContestar2Veces}
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                  {Array.isArray(lista) &&
                    lista.map((item, index) => {
                      return (
                        <IonCard className="ionCard" key={index}>
                          <IonCardHeader>
                            <IonCardTitle>{item.producto}</IonCardTitle>
                          </IonCardHeader>
                          <img
                            width={item.id === Producto.Cafe ? 300 : 50}
                            src={item.icono}
                            alt="ayuda"
                          ></img>
                          <IonCardContent>{item.descripcion}</IonCardContent>

                          {item.precio === 0 ? (
                            ""
                          ) : (
                            <IonBadge className={styles.bagge} color="primary">
                              <span className={styles.moneda}>
                                {item.precio}
                              </span>
                              <img
                                width={30}
                                src="/assets/icon/moneda.png"
                                alt="moneda"
                              ></img>
                            </IonBadge>
                          )}
                          <div className="botones">
                            <IonButton
                              shape="round"
                              color="success"
                              className={styles.boton_comprar}
                              size="large"
                              fill="solid"
                              disabled={!item.puedoComprar}
                              onClick={() => comprar(item)}
                            >
                              {item.id === Producto.Moneda_Publicidad ||
                              item.id === Producto.QUITAR_PUBLICIDAD
                                ? t("see_advertising")
                                : t("buy")}
                            </IonButton>

                            <p className="hidden"></p>
                          </div>
                        </IonCard>
                      );
                    })}
                </div>
              )}

              {!authUser?.isAuth && (
                <div className={styles.caja_profile}>
                  <h3>{t("create_account")}</h3>
                  <p>{t("store_not_auth")}</p>
                  <IonButton
                    color="success"
                    shape="round"
                    onClick={abrirModalLogin}
                  >
                    <IonIcon slot="start" icon={personAdd} />
                    {t("create_account_button")}
                  </IonButton>
                </div>
              )}

              <div className="margin_buttom_banner"></div>
            </IonContent>
          </IonCol>
          <IonCol size="12" size-md="2" className="hide_pc">
            <Media
              query="(min-width: 600px)"
              render={() => (
                <BannerLocalLateralGrande></BannerLocalLateralGrande>
              )}
            />
          </IonCol>
        </IonRow>

        <CrearCuenta
          estadoModal={loginModal}
          eventoCerrar={cerrarModalLogin}
          navegar={false}
        ></CrearCuenta>
      </IonContent>
    </IonPage>
  );
};

export default Store;
